<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="14px" width="14px" fill="#000">
        <path d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z"/>
    </svg>
</template>

<script>
export default {
    name: 'DoneIconComponent',
}
</script>