import { render, staticRenderFns } from "./button.vue?vue&type=template&id=6d0e3c66&scoped=true&"
import script from "./button.vue?vue&type=script&lang=js&"
export * from "./button.vue?vue&type=script&lang=js&"
import style0 from "./button.vue?vue&type=style&index=0&id=6d0e3c66&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d0e3c66",
  null
  
)

export default component.exports