<template>
  <div class="full-width">
    <header-component />
    <div id="hero-image" class="hero row col-xs-12 relative-position">
      <div class="row wrap first-screen items-stretch col-xs-12 text-white relative-position">
        <div class="row col-xs-12 q-pt-lg q-pb-md">
          <div class="row items-start col-xs-12 col-md-4 offset-md-2 text-bolder">
            <h1 class="col-xs-12 text-h1">
              Simple, useful and outstanding link in bio page
            </h1>
            <div class="row col-xs-12 q-mt-lg">
              <div class="scroller row col-xs-12 text-h3">
                <div>
                  <div class="row col-xs-12 items-center">
                    <div class="q-px-xs">
                      For you and your business
                    </div>
                  </div>
                  <div class="row col-xs-12 items-center">
                    <div class="q-px-xs">
                      Ready to use page templates
                    </div>
                  </div>
                  <div class="row col-xs-12 items-center">
                    <div class="q-px-xs">
                      Register and setup in 3 min
                    </div>
                  </div>
                  <div class="row col-xs-12 items-center">
                    <div class="q-px-xs">
                      Perfect for promoting your Instagram
                    </div>
                  </div>
                </div>
              </div>
              <div class="row col-xs-12 q-pt-lg q-pb-xl">
                <StickyElement visibleOnDirection="disabled" stickMode="element-start" class="row col-xs-12">
                  <div class="main-cta-container row col-xs-12">
                    <button-component push size="lg" header="Create Page" description="Free" color="green" class="col-xs-12 col-sm-auto q-my-sm
                      multilink-overview-btn-try-what" :class="{ 'q-px-xl': !isMobile }"
                      action-label-to-log="main-try-what" title="Create a free link in bio using a template"
                      href="public-templates">
                    </button-component>
                  </div>
                </StickyElement>
                <h2 class="col-xs-12 text-h2 no-margin">
                  Signup now and get plan for FREE
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <kinesis-container :event="isMobile ? 'scroll' : 'move'" class="personal-hero">
        <kinesis-element :strength="isMobile ? 40 : 10" type="depth" tag="img" alt="hero image"
          :src="`${imagesSubDomain}/statics/about/personal_${v}_1.webp`"
          class="hero-image">
        </kinesis-element>
        <kinesis-element :strength="isMobile ? 50 : 20" type="depth" tag="img" alt="buttons in hero image"
          :src="`${imagesSubDomain}/statics/about/personal_${v}_2.webp`"
          class="hero-image absolute-top">
        </kinesis-element>
        <kinesis-element :strength="isMobile ? 50 : 20" type="depth" tag="img" alt="social media in hero image"
          :src="`${imagesSubDomain}/statics/about/personal_${v}_3.webp`"
          class="hero-image absolute-top">
        </kinesis-element>
      </kinesis-container>
    </div>
    <div class="row relative-position">
      <counter-component id="counter" class="col-xs-12 col-md-3 offset-md-2 shadow-label" bgColor="bg-primary-2" />
    </div>
    <div id="features" class="features-block row col-xs-12 justify-center">
      <vue-slick-carousel key="features-carousel" :arrows="isMobile" :dots="!isMobile"
        autoplay :infinity="true" :fade="false" :speed="1000" :autoplaySpeed="6000" class="col-xs-12 col-lg-8">
        <div>
          <div class="row feature-item full-width justify-center">
            <div class="row col-xs-12 col-sm-6 justify-center">
                <img class="feature-image" alt="all in one link" width="400" height="400"
                  :src="`${imagesSubDomain}/statics/about/all_messengers_sm.webp`" />
            </div>
            <div class="col-xs-12 col-sm-6 feature-text">
              <h2 class="text-h4 text-weight-light">
                Let your client choose a convenient way to contact you
              </h2>
              <div class="text-weight-bold q-py-sm">
                Get more hits from potential clients
              </div>
              <done-icon-component class="q-mr-sm" />
              Instagram and other social networks allow posting only 1 link in a profile.
              With {{ currentPlatformName }} you can place any number of links to your products,
              services, special offers, messengers and social networks.
              <br><done-icon-component class="q-mr-sm" />
              You no longer need a website to work effectively on the Internet.
              {{ currentPlatformName }} allows you to create your own web page
              in a few minutes without a designer or programmer.
              <br><done-icon-component class="q-mr-sm" />
              Create smart links to online messaging apps, SMS, or social networks
              that allow starting a conversation at the click of a button.
            </div>
          </div>
        </div>
        <div>
          <div class="row feature-item full-width justify-center">
            <div class="row col-xs-12 col-sm-6 justify-center">
              <img class="feature-image" alt="online payments in link in bio" width="400" height="400"
                :src="`${imagesSubDomain}/statics/about/payment_sm.webp`" />
            </div>
            <div class="col-xs-12 col-sm-6 feature-text relative-position">
              <h2 class="text-h4 text-weight-light">
                Make money
              </h2>
              <div class="text-weight-bold q-py-sm">
                Create your own mini-store
              </div>
              <done-icon-component class="q-mr-sm" />
              Display your products to boost sales
              <br><done-icon-component class="q-mr-sm" />
              Set up a payment system that is more convenient for you to use,
              <br>for example, <span class="text-weight-bold">Paypal, Stripe</span>
              <br>
              <done-icon-component class="q-mr-sm" />
              Create special offers nobody can not refuse. Add enticing texts, pictures, and videos to engage your
              clients.
              Use FAQ section and start a time-limited promotion with a countdown timer.
            </div>
          </div>
        </div>
        <div>
          <div class="row feature-item full-width justify-center">
            <div class="row col-xs-12 col-sm-6 justify-center">
              <img class="feature-image" alt="client base in yapepage" width="400" height="400"
                :src="`${imagesSubDomain}/statics/about/client-base_sm.webp`" />
            </div>
            <div class="col-xs-12 col-sm-6 feature-text relative-position">
              <h2 class="text-h4 text-weight-light">
                Drive more leads and sales on Instagram
              </h2>
              <div class="text-weight-bold q-py-sm">
                No manual payment and request processing anymore, let {{ currentPlatformName }} do it for you.
              </div>
              <br><done-icon-component class="q-mr-sm" />
              Start selling online now. Create an online store in 20 minutes without designers and programmers.
              <br><done-icon-component class="q-mr-sm" />
              Electronic materials (files, guides, images, etc.)
              will be <span class="text-weight-bold">automatically</span>
              sent to the buyer after payment;
              <br><done-icon-component class="q-mr-sm" />
              Receive instant notifications of new orders in messengers or email or use our built-in CRM
            </div>
          </div>
        </div>
        <div>
          <div class="row feature-item full-width justify-center">
            <div class="row col-xs-12 col-sm-6 justify-center">
              <img class="feature-image" alt="online store in link in bio" width="400" height="400"
                :src="`${imagesSubDomain}/statics/about/shop_sm.webp`" />
            </div>
            <div class="col-xs-12 col-sm-6 feature-text relative-position">
              <h2 class="text-h4 text-weight-light">
                Creating a website for your offline business is &nbsp;simple&nbsp;and&nbsp;fast.
              </h2>
              <div class="text-weight-bold q-py-sm">
                Increase the efficiency of your business
              </div>
              <done-icon-component class="q-mr-sm" />
              Build your customer base and contact them in 1 click
              <br><done-icon-component class="q-mr-sm" />
              Let customers experience the convenience of online booking in a few clicks
              <br><done-icon-component class="q-mr-sm" />
              Share the list of your services and their prices - get more new customers!
              <div class="text-weight-bold q-py-sm">
                Do you post on Instagram with your free time?
              </div>
              <done-icon-component class="q-mr-sm" />
              In {{ currentPlatformName }} you can save a beautifully designed picture with
              a list in a couple of clicks
            </div>
          </div>
        </div>
        <div>
          <div class="row feature-item full-width justify-center">
            <div class="row col-xs-12 col-sm-6 justify-center">
              <img class="feature-image" alt="customize your link in bio" width="400" height="400"
                :src="`${imagesSubDomain}/statics/about/colors_sm.webp`" />
            </div>
            <div class="col-xs-12 col-sm-6 feature-text" :class="isMobile ? 'text-center' : ''">
              <h2 class="text-h4 text-weight-light">Create and customize your bio link in minutes</h2>
              <div class="text-weight-bold q-py-sm">
                Make an eye-catching page
              </div>
              Share your content, products, videos, music, podcast, events and more.
              Create unique design in you link in bio by
              wide range of different components:<br>
              <br><done-icon-component class="q-mr-sm" />
              pictures, sliders and videos
              <br><done-icon-component class="q-mr-sm" />
              tutorials, articles, blog and other website links
              <br><done-icon-component class="q-mr-sm" />
              maps - show where you can be found
              <br><done-icon-component class="q-mr-sm" />
              cards of the marathon or upcoming event
              <br><done-icon-component class="q-mr-sm" />
              customize the landing page components and background in your brand colors
              <br><done-icon-component class="q-mr-sm" />
              animate your page
            </div>
          </div>
        </div>
        <div>
          <div class="row feature-item full-width justify-center">
            <div class="row col-xs-12 col-sm-6 justify-center">
              <img class="feature-image" alt="analytics for your link in bio" width="400" height="400"
                :src="`${imagesSubDomain}/statics/about/analytics_sm.webp`" />
            </div>
            <div class="col-xs-12 col-sm-6 feature-text relative-position">
              <h2 class="text-h4 text-weight-light">Analytics</h2>
              <div class="text-weight-bold q-py-sm">
                Improve your business with data
              </div>
              <br><done-icon-component class="q-mr-sm" />
              Check the traffic of your pages
              <br><done-icon-component class="q-mr-sm" />
              Track the number of clicks on each element on the landing page
              and find out the most attractive design
              <br><done-icon-component class="q-mr-sm" />
              Use integrated data analizer or external services:
              <span class="text-weight-bold">Google Analytics, Facebook Pixel, TikTok Pixel</span>
              <br><done-icon-component class="q-mr-sm" />
              Get analytics on customer behavior, booking statuses and products sales
            </div>
          </div>
        </div>
      </vue-slick-carousel>
    </div>
    <div class="faq-block row col-xs-12 justify-center q-py-md">
      <h3 class="col-xs-12 text-h4">Got questions?</h3>
      <VueFaqAccordion
        :items="faqLanding"
        questionProperty="question" 
        answerProperty="answer"
        activeColor="#ffffff"
        borderColor="#4a73c5"
        fontColor="#ffffff"
        class="row justify-center col-xs-12 col-sm-8"/>
    </div>
    <div class="contact-block row col-xs-12">
      <div class="col-xs-12 q-pt-lg q-pb-xl">
        <h3 class="text-h4 q-ma-md">
          How to contact us
        </h3>
        <div class="q-py-md">Write to us, we will be happy to help</div>
        <div class="row items-center justify-center">
          <a id="open-tg-link" class="q-mx-sm contact-btn" :title="`View the $${this.currentPlatformName} telegram channel`"
            :href="telegramUrl">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="24px"
              width="24px" version="1.1" viewBox="0 0 512 512" xml:space="preserve">
              <g>
                <path fill="currentColor"
                  d="    M477.805,102.98l-67.327,317.516c-5.08,22.41-18.326,27.984-37.15,17.431l-102.585-75.596l-49.497,47.607    c-5.477,5.478-10.06,10.061-20.617,10.061l7.37-104.479l190.129-171.805c8.268-7.37-1.792-11.454-12.848-4.083L150.233,287.633    l-101.19-31.672c-22.011-6.873-22.408-22.012,4.581-32.568L449.419,70.911C467.744,64.039,483.779,74.993,477.805,102.98z" />
              </g>
            </svg>
          </a>
          <a id="open-ig-link" class="q-mx-sm contact-btn" :title="`View the $${this.currentPlatformName} Instagram page`"
            :href="instagramProfileUrl">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" height="24px" width="24px" fill="currentColor" viewBox="0 0 56.7 56.7"
              enable-background="new 0 0 56.7 56.7" xml:space="preserve">
              <g>
                <path
                  d="M28.2,16.7c-7,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8S41,36.5,41,29.5S35.2,16.7,28.2,16.7z M28.2,37.7  c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2S32.7,37.7,28.2,37.7z" />
                <circle cx="41.5" cy="16.4" r="2.9" />
                <path
                  d="M49,8.9c-2.6-2.7-6.3-4.1-10.5-4.1H17.9c-8.7,0-14.5,5.8-14.5,14.5v20.5c0,4.3,1.4,8,4.2,10.7c2.7,2.6,6.3,3.9,10.4,3.9  h20.4c4.3,0,7.9-1.4,10.5-3.9c2.7-2.6,4.1-6.3,4.1-10.6V19.3C53,15.1,51.6,11.5,49,8.9z M48.6,39.9c0,3.1-1.1,5.6-2.9,7.3   s-4.3,2.6-7.3,2.6H18c-3,0-5.5-0.9-7.3-2.6C8.9,45.4,8,42.9,8,39.8V19.3c0-3,0.9-5.5,2.7-7.3c1.7-1.7,4.3-2.6,7.3-2.6h20.6   c3,0,5.5,0.9,7.3,2.7c1.7,1.8,2.7,4.3,2.7,7.2V39.9L48.6,39.9z" />
              </g>
            </svg>
          </a>
          <a id="open-wa-support" class="q-mx-sm contact-btn" title="Message on WhatsApp"
            :href="`https://wa.me/${phones && phones.supportPhone}`">
            <svg enable-background="new 0 0 100 100" height="24px" width="24px" version="1.1" viewBox="0 0 100 100"
              xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g>
                <defs>
                  <rect fill="none" height="100" width="100" />
                </defs>
                <path fill="currentColor"
                  d="M95,49.247c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L5,95.406   l7.975-23.522c-4.023-6.606-6.34-14.354-6.34-22.637c0-24.213,19.781-43.841,44.184-43.841C75.223,5.406,95,25.034,95,49.247    M50.818,12.388c-20.484,0-37.146,16.535-37.146,36.859c0,8.066,2.629,15.535,7.076,21.611l-4.641,13.688l14.275-4.537   c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.858C87.964,28.924,71.301,12.388,50.818,12.388    M73.129,59.344c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.494c-0.993-0.359-1.717-0.539-2.438,0.536   c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.334   c-3.219-2.847-5.393-6.364-6.025-7.44c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882   c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.976   c-0.902-2.151-1.803-1.793-2.436-1.793c-0.631,0-1.354-0.09-2.076-0.09s-1.896,0.269-2.889,1.344   c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.114c0.541,0.716,7.49,11.92,18.5,16.223   C63.2,71.177,63.2,69.742,65.186,69.562c1.984-0.179,6.406-2.599,7.312-5.107C73.398,61.943,73.398,59.792,73.129,59.344" />
              </g>
            </svg>
          </a>
          <a id="open-email-support" class="q-mx-sm contact-btn" title="Send email" :href="`mailto:${supportEmail}`">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48" height="24px" width="24px"><path fill="currentColor" d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm17-15.1 17-11.15V11L24 21.9 7 11v2.75Z"/></svg>
          </a>
        </div>
      </div>
    </div>
    <footer-component />
    <cookie-modal />
  </div>
</template>

<script>
import phones from './../../ui/src/utilities/consts/common/phones';
import CounterComponent from './components/counter';
import { KinesisContainer, KinesisElement } from 'vue-kinesis';
import ButtonComponent from './components/button.vue';
import DoneIconComponent from './components/done-icon.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import StickyElement from 'vue-sticky-element';
import HeaderComponent from './components/header.vue';
import FooterComponent from './components/footer.vue';
import VueFaqAccordion from 'vue-faq-accordion';
import cookieModal from './components/cookie-modal.vue';

export default {
  name: 'LandingOverviewEn',
  components: {
    CounterComponent,
    KinesisContainer,
    KinesisElement,
    ButtonComponent,
    DoneIconComponent,
    VueSlickCarousel,
    StickyElement,
    HeaderComponent,
    FooterComponent,
    VueFaqAccordion,
    cookieModal,
  },
  props: {
    version: {
      type: String,
      default: 'b',
    },
  },
  meta() {
    const canonicUrl = `${this.currentOrigin}`;
    return {
      meta: {
        keywords: { name: 'keywords', content: 'link in bio, taplink, Instagram multilink' },
        'og:url': { property: 'og:url', content: canonicUrl },
      },
      link: {
        canonical: { rel: 'canonical', href: canonicUrl },
      },
    };
  },
  data() {
    return {
      phones,
      isMobile: true,
      currentOrigin: 'https://yape.page',
      currentPlatformName: 'YAPE.page',
      supportEmail: 'support@yape.page',
      instagramProfileUrl: 'https://www.instagram.com/yape.page/',
      trustpilotUrl: 'https://www.trustpilot.com/review/yape.page',
      imagesSubDomain: 'https://images.seeyour.info',
      telegramUrl: 'https://t.me/yapepage',
      faqList: [...Array(10).keys()],
      imageList: [...Array(3).keys()],
      heroImageRation: 1,
      heroDesktopImageSize: 0.8,
      heroMobileImageSize: 1.2,
    };
  },
  computed: {
    v() {
      return this.version ? this.version.toLowerCase() : 'b';
    },
    faqLanding() {
      return [
        {
          question: `What is a ${this.currentPlatformName} link in bio for?`,
          answer: `${this.currentPlatformName} page is a mini web page or a business card site,
    which can be done quickly and conveniently on your own, without any special knowledge in programming and layout. `,
        }, {
          question: `What is ${this.currentPlatformName} used for?`,
          answer: `${this.currentPlatformName} is a platform that enables you to share more than one link from your social media bios. It’s used on social media, email signatures, video descriptions, show notes, live streams, webinars and so on`,
        }, {
          question: `Why do I need a ${this.currentPlatformName}?`,
          answer: `${this.currentPlatformName} is a single link that you can use to connect audiences to everything you are, everything you do and everything you care about. It enables you to share many links in one, so your followers, visitors and customers can find everything they’re looking for in one place. <br/>
  You can add it to your bio link on social media platforms like Tiktok and Instagram, include it in your email signatures.`,
        }, {
          question: 'How do I make a link in bio?',
          answer: `All you need to do is take a few simple steps: <br/>
    Register at <a id="open-signup-from-faq" title="Register at ${this.currentPlatformName}" href="${this.currentOrigin}/signup-select"> ${this.currentPlatformName}</a>. If you have already registered, proceed to the second stage
    There are several ways to create a ${this.currentPlatformName} page: <br/>
    1) use ready-made templates <br/>
    2) choose the necessary elements for your site yourself. You can also see the instructions. <br/>
    3) use the turnkey page service. We will do everything for you according to your request. Service price starts at 30 USD`,
        }, {
          question: `Is ${this.currentPlatformName} free?`,
          answer: `We have both free,
    and paid plans (PRO and MAX).
    More details about the cost and features of ${this.currentPlatformName} plans can be found on the page
    <a id="open-tariffs-from-faq" title="Favorable tariffs for link in bio" href="${this.currentOrigin}/tariffs"> "Plans" </a> `,
        }, {
          question: 'What is a trial plan?',
          answer: `Any paid plan (PRO and MAX) can be used absolutely for free, for the first 3 days.
    At the end of this period, you are transferred to a free plan, and the paid features will be turned off.
    When you pay for the plan, they are activated again. Reconfiguration will not be needed. `,
        }, {
          question: `Can I sell products on ${this.currentPlatformName}?`,
          answer: `Yes, with the help of ${this.currentPlatformName} you can create a catalog of your products or services, set up a payment system that is more convenient for you to use, and set up automatic sending of your online products after the purchase.
  <br/> <a id="open-instructions-products-from-faq" title="How to automate the sale of guides, courses, marathons with a ${this.currentPlatformName} Page?" href = "${this.currentOrigin}/blog/en/knowledge-base/how-to-add-a-product-service-to-a-landing-page/"> "More about selling products, services, guides and marathons" </a> `,
        }, {
          question: `I want to share free content in exchange for data. Can this be done at ${this.currentPlatformName}?`,
          answer: `Yes, with the help of ${this.currentPlatformName} you can create a catalog of your free products or services, and set up automatic sending of your online products after the customer has filled in contact information.
  <br/> <a id="open-instructions-free-products-from-faq" title="How to add a product or service for ${this.currentPlatformName}?" href = "${this.currentOrigin}/blog/en/knowledge-base/how-to-add-a-product-service-to-a-landing-page/"> "More about the catalog of products, services, guides and marathons" </a> `,
        },
        {
          question: `How can I drive more traffic to and through my ${this.currentPlatformName}?`,
          answer: `Sharing your ${this.currentPlatformName} on every social platform you have makes it easy for your most important content to be seen and engaged with by all of your followers.<br/><br/>
  Once visitors arrive on your page, easy-to-understand analytics help you quickly and easily discover where they’re coming from, and what they’re clicking on. You can immediately see what’s working and what’s not and improve your ${this.currentPlatformName} page on the fly with different link placement, prioritized links, subheadings, animation and more to make sure your traffic is landing exactly where you want it!`,
        }, {
          question: `Do I need a website to use ${this.currentPlatformName}?`,
          answer: `No, you don’t! ${this.currentPlatformName} can act as your very own mini-website to share, sell and grow without any of the time and effort it takes to build and maintain a regular website. You can create a design that fully reflects your personality and brand in seconds, with no knowledge, skills or experience needed. If you already have a website, that’s great: you can add it to your ${this.currentPlatformName} page.`,
        }
      ];
  }
  },
  mounted() {
    const mql = window.matchMedia("(max-width: 600px)");
    this.isMobile = !!mql.matches;
    mql.addEventListener("change", (event) => {
      this.isMobile = !!event.matches;
    });
  },
};
</script>

<style lang="stylus" scoped>
  @import './assets/css/variables.styl'

  .hero
    color #ffffff
    background-color lighten($primary, 80%)

  .first-screen
    background-color $secondary
    background linear-gradient(-45deg, $deep-orange, #e73c7e, $secondary, $accent, $info)
    background-size 300% 300%
    animation gradient-wave 15s infinite
    padding-left 24px
    padding-right 24px

  .personal-hero
    position relative
    top -50px

  .hero-image
    width 100vw
    height 100vw
  .scroller
    height 1.5em
    position relative
    overflow hidden

  .scroller > div
    position absolute
    top 0
    animation slide 7s infinite

  .features-block
    padding-top 100px
    padding-bottom 100px
    .feature-item
      flex-wrap no-wrap
    .feature-image
      width 100%
      height auto
      aspect-ratio auto 400 / 400;
    .feature-text
      padding-left 24px
      padding-right 24px

  .faq-block
    text-align center
    color $primary
    background-color lighten($primary, 80%)

    & >>> .accordion
      border none
    & >>> .accordion__item
      color #ffffff
      background-color $primary
      border-radius $rounded-radius
      margin-bottom 16px
    & >>> a
      color #ffffff
    & >>> .accordion__toggle-button:before,
    & >>> .accordion__toggle-button:after
      background-color #ffffff
    & >>> .accordion__title
      font-size 1.25rem
      font-weight 500
      padding 16px 24px
      text-align left
    & >>> .accordion__value
      padding 0 24px 16px 24px

  .contact-block
    background-color $primary
    color #ffffff
    text-align center

    .contact-btn
      color #ffffff
      text-decoration none
  
  .main-cta-container.vue-sticky-element--stuck
    top unset
    bottom 0
    left 0
    right 0
    padding 0px 16px 16px 16px
    background linear-gradient(0deg, $secondary 0%, $secondary 80%, transparent 80%, transparent 100%)
    justify-content center
    align-items center
    display flex

  @media screen and (min-width 599px) {
    h1.text-h1 {
      font-size 50px
    }
    h2.text-h2 {
      font-size 20px
    }
    .text-h3 {
      font-size 20px
    }

    .first-screen {
      padding-left 16px
      padding-right 16px
    }
    .personal-hero {
      position absolute
      right 0
      top 10%
    }
    .hero-image {
      width 80vh
      height 80vh
    }
    .features-block {
      .feature-item {
        flex-wrap wrap
      }
      .feature-image {
        width 400px
      }
      .feature-text {
        padding-left 16px
        padding-right 16px
      }
    }
  }

  @keyframes gradient-wave {
      0% {
        background-position 0% 50%;
      }
      50% {
        background-position 100% 50%;
      }
      100% {
        background-position 0% 50%;
      }
  }

  @keyframes slide {
    0% {
      top 1.5em;
    }
    10% {
      top 0;
    }
    25% {
      top 0;
    }
    35% {
      top -1.5em;
    }
    50% {
      top -1.5em;
    }
    60% {
      top -3em;
    }
    75% {
      top -3em;
    }
    85% {
      top -4.5em;
    }
    100% {
      top -4.5em;
    }
  }
</style>
