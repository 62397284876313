<template>
    <div class="modal q-pa-md" :class="{ opened: !isClosed }">
        <div>
            To improve our service and enhance your experience on our website, we use cookies.
            We also allow selected third parties to store cookies to provide certain functionality.
            You can manage them through your web browser settings. Should you prevent these cookies,
            we can't guarantee your security or predict how our website will function.
        </div>
        <div class="q-pt-md">
            <button-component
                header="ACCEPT"
                @click="close"
                title="cookies warning">
            </button-component>
        </div>
    </div>
</template>

<script>
import ButtonComponent from './button.vue';

export default {
  name: 'CookieModal',
  components: {
    ButtonComponent,
  },
  data() {
    return {
        isClosed: !!this.getCookie('syi_enable_cookie'),
    };
  },
  methods: {
    close() {
        this.setCookie('syi_enable_cookie', 1, 365);
        this.isClosed = true;
    },
    setCookie(name, value, days) {
        let expires = "";
        value = JSON.stringify(value);
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie =
          encodeURIComponent(name) + '=' + encodeURIComponent(value) +
          expires + "; path=/";
    },
    getCookie(name) {
      let value = decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(name).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
      if (value && ((value.substring(0, 1) === '{' && value.substring(value.length - 1, value.length) === '}') || (value.substring(0, 1) === '[' && value.substring(value.length - 1, value.length) === ']'))) {
        try {
          value = JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
      return value;
    },
  },
}
</script>

<style lang="stylus" scoped>
    @import '../assets/css/variables.styl'
    .modal
        background #fff
        border-radius $rounded-radius 0 0 $rounded-radius
        transform translate(110%,-50%)
        position fixed
        top 50%
        right 0
        margin-left 50px
        box-shadow 0px 0px 10px 0px rgb(0 0 0 / 20%)
        transition transform 1.5s
        &.opened
            transform translate(0,-50%)

        @media screen and (min-width 599px)
            &
                max-width 400px

</style>
