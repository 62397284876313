<template>
    <header class="header row items-center">
        <div role="toolbar" class="q-toolbar row items-center full-width">
            <div class="row col-xs-12 items-center">
                <div class="col-xs-auto row items-center cursor-pointer">
                    <svg class="logo" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" viewBox="0 0 272.468 166.371">
                        <text style="fill: rgb(51, 51, 51); font-family: Arial, sans-serif; font-size: 100px; font-weight: 700; white-space: pre;" transform="matrix(1, 0, 0, 1, -32.056553, 18.58812)"><tspan x="36.921" y="57.711">YAPE</tspan><tspan x="36.92100143432617" dy="1em">&ZeroWidthSpace;</tspan></text>
                        <text style="fill: rgb(51, 51, 51); font-family: Arial, sans-serif; font-size: 100px; font-weight: 700; white-space: pre;" x="3.438" y="159.772">PA</text>
                        <path style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(87, 201, 195);" transform="matrix(0.690184, 0, 0, 0.690184, -77.470596, -59.190254)" d="M 411.338 265.252 A 52.16 52.16 0 1 1 359.178 213.092 L 359.178 265.252 Z" bx:shape="pie 359.178 265.252 0 52.16 90 360 1@771758fb"/>
                        <rect x="214" y="115.239" width="52.028" height="17.517" style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(74, 115, 197);"/>
                        <rect x="214" y="142.2" width="52.028" height="17.517" style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(111, 176, 244);"/>
                        <rect x="214" y="88" width="52.028" height="17.517" style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(104, 71, 173);"/>
                    </svg>
                </div>
                <div class="col-xs row items-center justify-end">
                    <button-component
                        action-label-to-log="open-examples"
                        color="transparent"
                        title="YAPE page examples"
                        header="Examples"
                        href="examples"/>
                    <button-component
                        action-label-to-log="open-tariffs"
                        color="transparent"
                        title="YAPE page prices"
                        header="Plans"
                        class="q-mr-md"
                        href="tariffs"/>
                </div>
                <div class="col-xs-auto row items-center justify-end">
                    <button-component
                        action-label-to-log="open-login"
                        color="secondary"
                        title="Log in to your personal YAPE.page account"
                        header="Login"
                        href="login"/>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import ButtonComponent from './button.vue';

export default {
    name: 'HeaderComponent',
  components: {
    ButtonComponent,
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables.styl'
.header
    background #ffffff
    color $secondary
    box-shadow 0 3px 5px -1px rgba(0 0 0 0.2), 0 5px 8px rgba(0 0 0 0.14), 0 1px 14px rgba(0 0 0 0.12)
    position relative
    padding 0 12px
    min-height 50px

    .logo
        width auto
        height 40px

@media (min-width: 600px)
    .header .logo
        width auto
        height 45px

</style>
