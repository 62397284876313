<template>
  <div
    class="counter-container row justify-center q-pb-md"
    :class="bgColor">
    <h2 class="col-xs-12 text-h5 q-pt-md">
      Clients trust us
    </h2>
    <div
      class="col-xs-12 q-pt-sm">
      Created more than
      <div class="row justify-center">
        <div class="text-h5 text-weight-bold counter">
          <div class="counter__content">
            22400
          </div>
        </div>
      </div>
      pages
    </div>
  </div>
</template>

<script>
export default {
  name: 'CounterComponent',
  props: {
    bgColor: {
      type: String,
      default: 'bg-primary-2',
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables.styl'
.counter-container
  background-color lighten($primary, 80%)
  color darken($primary, 30%)
  text-align center
  .absolute-center
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
  .counter
    display flex
    letter-spacing 10px
    padding 0 0 0 10px
    position relative
    border-radius 4px
    vertical-align middle
    outline 0
    height 2em
    max-width 100%
    margin 4px
    align-items center

    &__content
      position relative
      font-weight 800
      flex: 10000 1 0%
      flex-wrap nowrap
      color #ffffff
      align-items center

    &:before
      content ''
      position absolute
      top 0
      bottom 0
      right 0
      left 0
      background-size contain
      background-image url('https://images.seeyour.info/statics/about/counter-bg.webp')
      background-repeat no-repeat

@media screen and (min-width: 599px)
  .counter-container
    border-radius 0 0 $rounded-radius $rounded-radius

</style>
