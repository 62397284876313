<template>
    <footer class="footer">
        <div class="row relative-position">
            <div class="row col-xs-12 justify-center text-overline">
                <div class="column col-xs-12 col-sm-auto q-px-lg">
                    <a href="/" title="About Us" target="_blank"
                        class="no-underline  router-link-exact-active router-link-active">
                        About Us
                    </a>
                    <a href="/tariffs" target="_blank" title="Choose the best plan for YAPE.page"
                        class="no-underline ">
                        Plans
                    </a>
                    <a href="/blog/en/" target="_blank" title="Read YAPE.page blog"
                        class="no-underline ">
                        Blog
                    </a>
                    <a href="/blog/en/knowledge-base/" target="_blank" title="Check Knowledge Base for YAPE.page"
                        class="no-underline ">
                        Knowledge Base
                    </a>
                    <a href="/examples/" target="_blank" title="Check link in bio examples"
                        class="no-underline ">
                        Examples
                    </a>
                    <a href="https://medium.com/@yape.page" target="_blank" title="Read YAPE.page blog"
                        class="no-underline ">
                        Medium Blog
                    </a>
                </div>
                <div class="column col-xs-12 col-sm-auto q-px-lg">
                    <a href="/privacy-policy"
                        title="Privacy Policy" class="no-underline ">
                        Privacy Policy
                    </a><a href="/about-terms" title="Offer Agreement" class="no-underline ">
                        Offer Agreement
                    </a><a href="/legal" title="Legal Information" class="no-underline ">
                        Legal Information
                    </a></div>
                <div class="col-xs-12 col-sm-auto q-px-lg column">
                    <div>language: ENGLISH</div>
                    <a title="The link in bio was made on the YAPE.page platform"
                        href="https://yape.page" target="_blank" class="no-underline ">
                        Made on YAPE.page
                    </a>
                    <div class="text-overline">Copyright © 2019 - 2023</div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
};
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables.styl'

.footer
    background-color $secondary
    color #fff
    padding 16px 0 200px 0
    text-align center

.text-overline
    font-size 0.75rem
    font-weight 500
    line-height 3rem
    letter-spacing 0.16667em
.no-underline
    text-decoration none
    color #fff

@media screen and (min-width: 599px)
    .footer
        text-align left
    .text-overline
        line-height 2rem
</style>
