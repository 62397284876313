<template>
    <a :id="actionLabelToLog" tabindex="0" :href="href" :title="title"
        class="q-btn q-btn-item q-btn--rounded q-btn--actionable q-focusable q-hoverable"
        :class="{
            [`q-btn-${size} bg-${color}`]: true,
            'q-btn--push': push,
        }"
        @click="$emit('click')">
        <span class="q-focus-helper"></span>
        <span class="q-btn__wrapper col row q-anchor--skip">
            <span class="q-btn__content col items-center q-anchor--skip justify-center row">
                <div class="row">
                    <div class="col-xs-12">
                        {{ header }}
                    </div>
                    <div v-if="description" class="col-xs-12 text-caption q-mb-xs">
                        {{ description }}
                    </div>
                </div>
            </span>
        </span>
    </a>
</template>

<script>

export default {
    name: 'ButtonComponent',
    props: {
        color: {
            type: String,
            default: 'secondary',
        },
        title: {
            type: String,
        },
        header: {
            type: String,
        },
        description: {
            type: String,
        },
        size: {
            type: String,
            default: 'sm',
        },
        push: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
        },
        actionLabelToLog:  {
            type: String,
        },
    },
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables.styl'

.q-btn
    cursor pointer
    display inline-flex
    flex-direction column
    align-items stretch
    position relative
    outline 0
    border 0
    vertical-align middle
    padding 0
    font-size 14px
    line-height 1.715em
    text-decoration none
    color inherit
    background transparent
    font-weight 500
    text-transform uppercase
    text-align center

    &.q-btn-lg
        padding-left 48px
        padding-right 48px
        font-size 20px

.q-btn--push.q-btn--actionable
    transition transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

.q-btn--rounded
    border-radius 14px

.bg-green
    background #91f143!important
    color $secondary !important

.bg-secondary
    background $secondary
    color #fff !important
.bg-transparent
    background transparent
    color $secondary !important

.q-focus-helper
    position absolute
    top 0
    left 0 /* rtlignore */
    width 100%
    height 100%
    pointer-events none
    border-radius inherit
    opacity 0
    transition background-color .3s cubic-bezier(.25,.8,.5,1), opacity .4s cubic-bezier(.25,.8,.5,1)
    &:before, &:after
      content ''
      position absolute
      top 0
      left 0 /* rtlignore */
      width 100%
      height 100%
      opacity 0
      border-radius inherit
      transition background-color .3s cubic-bezier(.25,.8,.5,1), opacity .6s cubic-bezier(.25,.8,.5,1)
    &:before
      background #000
    &:after
      background #fff
.q-focusable:focus, .q-manual-focusable--focused, .q-hoverable:hover
    > .q-focus-helper
      background currentColor
      opacity .15
      &:before
        opacity .1
      &:after
        opacity .4
.q-focusable:focus, .q-manual-focusable--focused
    > .q-focus-helper
      opacity .22

.q-btn__wrapper
    min-height 2.572em
    border-radius inherit
    width 100%
    height 100%
    .q-btn-lg &
        padding 4px 16px
    .q-btn-sm &
        padding 0 16px

.q-btn--push.q-btn--actionable .q-btn__wrapper:before
    transition top 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), bottom 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), border-bottom-width 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

.q-btn--push .q-btn__wrapper:before
    border-bottom 3px solid rgba(0,0,0,0.15)
.q-btn__wrapper:before
    content ''
    display block
    position absolute
    left 0
    right 0
    top 0
    bottom 0
    border-radius inherit
    
    .bg-transparent &
        box-shadow none
.q-btn__content
    transition opacity 0.3s
    z-index 0
    text-align center
    font-weight 400
    width 100%

.text-caption
    font-size 0.75rem
    line-height 1.25rem
    letter-spacing 0.03333em

</style>